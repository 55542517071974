import React, { useState, Children } from 'react';

import { Icon } from '@common/components/icon';
import { combineClassNames } from '@utils/combineClassNames';

import type { IconTypes } from '@common/components/icon';

export type HeaderItemProps = {
  title: string;
  onClick: () => void;
  active: boolean;
  icon?: IconTypes;
};

type TabsVariants = 'borders' | 'background' | 'full_width';

type ChildType = React.ReactElement<ItemProps>;
type ChildrenType = (ChildType | undefined | false)[];

type Props = {
  children: ChildType | ChildrenType;
  TabsHeaderItem?: React.ComponentType<HeaderItemProps>;
  containerClassName?: string;
  className?: string;
  initialTabIndex?: number;
  onChange?: (tabIndex: number) => void;
  vertical?: boolean;
  variant?: TabsVariants;
  reverse?: boolean;
};

type TabsComponentType = {
  (props: Props): JSX.Element;
  Item: (props: ItemProps) => JSX.Element;
};

const TabsComponent: TabsComponentType = ({
  children,
  initialTabIndex = 0,
  TabsHeaderItem,
  className,
  containerClassName,
  onChange,
  vertical,
  variant = 'borders',
  reverse,
}: Props) => {
  const [currentIndex, setIndex] = useState(initialTabIndex);

  const handleChange = (value: number) => {
    setIndex(value);
    onChange?.(value);
  };

  const fullContainerClassName = combineClassNames('Tabs', containerClassName, {
    'Tabs--vertical': vertical,
    [`Tabs--${variant}`]: !!variant,
  });

  const processedChildren = (() => {
    let processed = Children.toArray(children) as ChildrenType;

    if (reverse) {
      processed = processed.reverse();
    }

    return processed.filter((tab): tab is ChildType => !!tab);
  })();

  return (
    <div className={fullContainerClassName}>
      <div className={combineClassNames('Tabs__Header', className)}>
        {processedChildren.map((tab, i) => tab && (
          TabsHeaderItem
            // @ts-expect-error
            ? <TabsHeaderItem {...tab.props} onClick={() => handleChange(i)} active={i === currentIndex} />
            : (
              <div
                className={`Tabs__Header__Item ${i === currentIndex ? ' Tabs__Header__Item--active' : ''}`}
                onClick={() => handleChange(i)}
              >
                <Icon type={tab.props.icon} isFilled={i === currentIndex} />
                {tab.props.title}
              </div>
            )
        ))}
      </div>
      {processedChildren[currentIndex]}
    </div>
  );
};

type ItemProps = {
  children: React.ReactNode;
  title: React.ReactNode;
  icon?: IconTypes;
};

const TabsItemComponent = ({ children }: ItemProps) => (
  <div className="Tabs__Item">{children}</div>
);

TabsComponent.Item = TabsItemComponent;

export default TabsComponent;
